import React from 'react';
import { Link } from "gatsby";
import "../../styles/sell-catalog.css";
import "../../styles/moo-sathi.css";
import logo from '../../images/moo-sathi/moofarm-logo.png';
import { Helmet } from "react-helmet";
import mooSathi from "../../images/moo-sathi/moo-sathi.png";
import LandingLogo from "../../images/landing/landing-logo.png";
import ArrowBtn from "../../images/moo-sathi/arrow-btn.png";
import fillform from "../../images/moo-sathi/fillform.png";
import VideoPlayer from "../../components/videoplayer/VideoPlayer";
import Slider from "react-slick";
import $ from "jquery";
import Rectangle from "../../images/moo-sathi/Rectangle.png";
import Instagram from "../../images/moo-sathi/instra.png"
import Facebook from "../../images/moo-sathi/facebook.png"
import Linkedin from "../../images/moo-sathi/linkedin.png"
import Youtube from "../../images/moo-sathi/youtube.png"
import Rating from "../../images/sell-landing/stars.png"
import GooglePlay from "../../images/sell-landing/googleplay.png"

let testimonialBuyerData = [
    {
        buyerName: "व्यक्ति का नाम",
        buyerdetails: "“हमारे साथ जुड़िये हमारे साथ जुड़िये हमारे साथ जुड़िये हमारे साथ जुड़िये हमारे साथ जुड़िये”",
        src: Rectangle,
    },
    {
        buyerName: "व्यक्ति का नाम",
        buyerdetails: "“हमारे साथ जुड़िये हमारे साथ जुड़िये हमारे साथ जुड़िये हमारे साथ जुड़िये हमारे साथ जुड़िये”",
        src: Rectangle,
    },
    {
        buyerName: "व्यक्ति का नाम",
        buyerdetails: "“हमारे साथ जुड़िये हमारे साथ जुड़िये हमारे साथ जुड़िये हमारे साथ जुड़िये हमारे साथ जुड़िये”",
        src: Rectangle,
    },
]

class Landing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const settings = {
            className: "slider variable-width slider-box",
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            autoplay: false,
            arrow: false
        }
        return (
            <div className='main-container'>
                <Helmet>
                    <link
                        href="https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700&display=swap"
                        rel="stylesheet"
                    />
                    <link
                        rel="stylesheet"
                        href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
                    />
                    <script src="js/script.js"></script>
                    <script src="https://kit.fontawesome.com/dd15047d18.js" crossorigin="anonymous"></script>
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
                </Helmet>
                <div className="container sell-banner-container">
                    <div className="row">
                        <div className="col-xs-12">
                            <img src={mooSathi} alt="Home" className="home-banner" />
                        </div>
                    </div>
                    <div className="sell-banner-content-box">
                        <div className="row">
                            <div className="col-xs-12 text-left">
                                <img src={LandingLogo} alt="Logo" className="main-logo" />
                            </div>
                        </div>
                        <div className="row text-left">
                            <div className="col-xs-12">
                                <p className="mooSathi-heading">
                                    किसानों के सारथी,<br></br>
                                    बनिए मू-साथी!
                                </p>
                                <div className='mt-12'>
                                <a
                                    className="moo-sathi-btn"
                                >
                                    मू-साथी बनिए {" "}
                                    <img src={ArrowBtn} className="btn-arrow" alt="Arrow Btn" />
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mooSathi-alert">
                    <div className="mooSathi-alert-view">
                        <p>अब रुके नहीं, बदलाव से जुड़े! मू साथी बने!</p>
                    </div>
                </div>
                <div className="container">
                    <div className="col-xs-12 main-list-container">
                        <h3 className="heading-view">क्या करेंगे मू-साथी?</h3>
                        <div className="all-list-view">
                            <div className="list-container">
                                <div className="list-num">
                                    <span>1</span>
                                </div>
                                <div className="list-contant">
                                    <p>मूफार्म पर पशु बिकवाएं और पाएं <span className="text-darkpink">₹1500</span></p>
                                </div>
                            </div>
                            <div className="list-container">
                                <div className="list-num">
                                    <span>2</span>
                                </div>
                                <div className="list-contant">
                                    <p>मू फीड बेचकर आसानी से <span className="text-darkpink">₹8000-₹10,000</span><br /> तक की कमाई करें!</p>
                                </div>
                            </div>
                            <div className="list-container">
                                <div className="list-num">
                                    <span>3</span>
                                </div>
                                <div className="list-contant">
                                    <p>पशु खरीदने में किसानों की मदद करें</p>
                                </div>
                            </div>
                        </div>
                        <div className="mooSathi-button">
                            <a className="popupbtn" >
                                मू-साथी बनिए
                                <img
                                    src={ArrowBtn}
                                    className="btn-arrow-bottom"
                                    alt="Arrow Btn"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="container MooSathi-benefits">
                    <h3 className="heading-view">मू साथी को मिलने वाले फायदे:</h3>
                    <div className="benefitsList-container">
                        <div className="benefitsList">
                            <p>मूफार्म के द्वारा मू साथी बनने की <span className='text-black'>ट्रेनिंग </span>सहायता</p>
                        </div>
                        <div className="benefitsList">
                            <p>सीधी एक फोन लाइन के द्वारा <span className='text-black'>फ्री डॉक्टर सुविधा</span></p>
                        </div>
                        <div className="benefitsList">
                            <p><span className='text-black'>दीवारों पर पेंटिंग</span> द्वारा फार्म का प्रचार</p>
                        </div>
                        <div className="benefitsList">
                            <p><span className='text-black'>ऐप</span> के जरिये किसानों के लिए पशु-खरीद की विजिट बुक</p>
                        </div>
                        <div className="benefitsList">
                            <p>हर हफ्ते <span className='text-black'>डिजिटल वीडियो/पोस्टर</span> की मार्केटिंग सहायता</p>
                        </div>
                        <div className="benefitsList">
                            <p>मार्केटिंग सम्बन्धी <span className='text-black'>पर्चे, पुस्तक</span> आदि प्रचार की सहायता</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="joinMooSathi-container">
                        <div className='row'>
                            <div className="col-md-6 col-xs-7">
                                <p className="joinMooSathi-heading">
                                    एक फॉर्म भरकर आप <br></br>
                                    बन सकते है मूसाथी! 👇
                                </p>
                                <div className="mooSathi-button">
                                    <a className="popupbtn" >
                                        फॉर्म भरे
                                        <img
                                            src={ArrowBtn}
                                            className="btn-arrow-bottom"
                                            alt="Arrow Btn"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-5 text-right">
                                <img
                                    src={fillform}
                                    className="fillform-img"
                                    alt="Arrow Btn"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container MooSathi-benefits">
                    <h3 className="heading-view text-center">बनिए मू-साथी, करिये अपने दम पर कमाई</h3>
                    <div className="mooSathi-video-player">
                        <VideoPlayer
                            url={"https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815673882_116816_admin_1_video_WhatsApp%20Video%202022-01-17%20at%206.18.12%20PMewe.mp4.mp4"}
                        />
                    </div>
                    <div className="mooSathi-button">
                        <a className="popupbtn" >
                            मू-साथी बनिए
                            <img
                                src={ArrowBtn}
                                className="btn-arrow-bottom"
                                alt="Arrow Btn"
                            />
                        </a>
                    </div>
                </div>
                <div className="container joinMooSathi-container">
                    <h3 className="heading-view">जानिये क्यों खरीदें पशु मूफार्म से</h3>
                    <div className='buy-animal-container row mt-12'>
                        <div className='col-md-6 col-xs-6'>
                            <div className='buy-animal-box'>
                                <p>दुधारू पशु, <br /> सही दाम</p>
                            </div>
                        </div>
                        <div className='col-md-6 col-xs-6'>
                            <div className='buy-animal-box'>
                                <p>पशु ट्रांसपोर्ट बिलकुल <br /> फ्री</p>
                            </div>
                        </div>
                        <div className='col-md-6 col-xs-6 mt-12'>
                            <div className='buy-animal-box'>
                                <p>दूध, ब्यात, नस्ल की <br /> 100% गारंटी</p>
                            </div>
                        </div>
                        <div className='col-md-6 col-xs-6 mt-12'>
                            <div className='buy-animal-box'>
                                <p>मूपका-खुरपका और <br /> लंगड़ा रोग के टीके लगे <br /> हुए पशु</p>
                            </div>
                        </div>
                    </div>
                    <div className="mooSathi-button">
                        <a className="popupbtn" >
                            मू-साथी बनिए
                            <img
                                src={ArrowBtn}
                                className="btn-arrow-bottom"
                                alt="Arrow Btn"
                            />
                        </a>
                    </div>
                </div>
                <div className="container MooSathi-benefits">
                    <h3 className="heading-view">मू-फीड क्यों, जानें ग्राहकों से</h3>
                    <Slider {...settings}>
                        {testimonialBuyerData.map((item, index) => {
                            return (
                                <div>
                                    <div className='contact-buyer'>
                                        <div className='contact-buyer-img'>
                                            <img
                                                src={item.src}
                                                className="buyer-img-view"
                                            />
                                        </div>
                                        <div className='contact-buyer-details'>
                                            <h3 className="buyer-name">{item.buyerName}</h3>
                                            <p className='buyer-details'>{item.buyerdetails}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className="container sell-footer-container">
                    <div className="row text-center">
                        <div className="col-xs-6 feed-social-background border-right">
                            <img src={LandingLogo} alt="Logo" className="footer-logo" />
                            <h4 className='join-me'>हमारे साथ जुड़िये</h4>
                            <div className="sell-social-icon">
                                <a href="https://www.instagram.com/mooofarm/" target="_blank">
                                    <img
                                        src={Instagram}
                                        alt="facebook"
                                        className="sell-social"
                                    />
                                </a>
                                <a href="https://www.facebook.com/mooofarm" target="_blank">
                                    <img
                                        src={Facebook}
                                        alt="Instagram"
                                        className="sell-social"
                                    />
                                </a>
                                <a
                                    href="https://in.linkedin.com/company/mooofarm"
                                    target="_blank"
                                >
                                    <img
                                        src={Linkedin}
                                        alt="Youtube"
                                        className="sell-social"
                                    />
                                </a>
                                <a
                                    href="https://www.youtube.com/c/MooofarmYoutube"
                                    target="_blank"
                                >
                                    <img
                                        src={Youtube}
                                        alt="Linkedin"
                                        className="sell-social"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="col-xs-6 text-center">
                            <h4 className='join-me'>डाउनलोड करें गूगल प्ले स्टोर से</h4>
                            <div className='my-16'>
                                <img src={Rating} alt="Rating" className="rating" />
                            </div>
                            <div>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.moo&hl=en_IN"
                                    target="_blank"
                                >
                                    <img
                                        src={GooglePlay}
                                        alt="Google Play"
                                        className="google-img-view"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container sell-popup-button-container">
                    <div className="row sell-popup-button-row text-center">
                        <div className="col-xs-12">
                            <div className="sell-popup-button">
                                <a
                                    className="popupbtn"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                >
                                    फार्म पर आएं - पशु देखें
                                    <img
                                        src={ArrowBtn}
                                        className="btn-arrow-bottom"
                                        alt="Arrow Btn"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="myModal" class="modal fade" role="dialog">
                    <div class="modal-dialog">
                        <div class="modal-content sell-catalog-model">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" 
                                // onClick={() => this.handleClearModalData()}
                                >
                                    &times;
                                </button>
                                <p class="modal-title-view">
                                    यहाँ अपना नंबर भरें - हमारी टीम आपसे तुरंत संपर्क करेगी!
                                </p>
                            </div>
                            <div class="modal-body">
                                <div className="row">
                                    <div className="col-xs-12 position-relative">
                                        <input
                                            type="tel"
                                            class="tel-input"
                                            placeholder="अपना नंबर यहां भरें"
                                            name="tel"
                                            maxLength={10}
                                            value={this.state.contactNumber}
                                            // onChange={(e) => {
                                            //     e.target.validity.valid ?
                                            //         this.handleContactInput(e.target.value)
                                            //         : alert("Contact number should contain Numbers only ")
                                            // }}
                                            pattern="[0-9]*"
                                        />
                                        <span className="country-code">+91</span>
                                        <div className="input-rightview">
                                            {/* {!this.state.showOtpText ? */}
                                             <a
                                                className="phone-btn"
                                                // onClick={() => this.handleSendCode()}

                                            >
                                                कोड भेजिए
                                            </a>
                                                {/* : <p>ओ.टी.पी. भेज दिया गया है</p>} */}
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        <p className="save-number-view"><i class="fa-solid fa-lock mr-6"></i> आपका नंबर हमारे पास सुरक्षित है</p>
                                    </div>
                                    {this.state.showOtpText &&
                                        <div className="col-xs-12 position-relative mt-12">
                                            <input
                                                type="password"
                                                class="tel-input-otp custom-mt-0"
                                                placeholder="4 अंको का ओ.टी.पी. डालें"
                                                name="tel"
                                                value={this.state.otp}
                                                maxLength={4}
                                                // onChange={(e) => {
                                                //     e.target.validity.valid ?
                                                //         this.handleOtpInput(e.target.value)
                                                //         : alert("OTP number should contain Numbers only ")
                                                // }}
                                                pattern="[0-9]*"
                                            />
                                            {/* {this.state.showResendOtp === false ?
                                                <div className="input-rightview">
                                                    <div id="timer">{`00:${this.state.timer}`}</div>
                                                </div>
                                                : */}
                                                <div className="input-rightview">
                                                    <button id="timer" 
                                                    // onClick={() => { this.handleReSendOtpCode() }}
                                                    >कोड दोबारा भेजिए</button>
                                                </div>
                                            {/* } */}
                                        </div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Landing;
